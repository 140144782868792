<template>
  <v-data-table
    :headers="headers"
    :items="agentsFiltered"
    class="elevation-1 h-100"
  >
    <template v-slot:top="{ pagination, options, updateOptions }">
      <v-toolbar
        flat
      >
        <v-toolbar-title>Mis agentes</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar agente..."
          single-line
          hide-details
        ></v-text-field>

        <v-spacer></v-spacer>
        <template>
          <div class="mb-2">
            <v-btn
              dark
              small
              class="mr-2"
              @click="openImportDialog"
            >
              Importar agentes
            </v-btn>
            <v-btn
              dark
              small
              class="mr-2"
              @click="exportAgents"
            >
              Exportar agentes
            </v-btn>
            <v-btn
              dark
              color=primary
              class=""
              @click="goToAgentPage"
            >
              Agregar nuevo
            </v-btn>
          </div>
        </template>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Esta seguro que desea borrar este item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="primary darken-1" text @click="deleteItemConfirm">Confirmar</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>

      <v-dialog v-model="dialogImport" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Suba el archivo CSV que quiera importar</v-card-title>
          <v-card-text>
            <a href="https://gwinn.com.ar/API/assets/excels/agentsExample.csv" target="_blank">Descargar CSV ejemplo</a>
            <br>
            <br>
            <v-file-input
              counter
              show-size
              truncate-length="50"
              max-width="500px"
              v-model="importCSVFile"
            ></v-file-input>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <div>
              <v-btn color="darken-1" text @click="closeImport">Cancelar</v-btn>
              <v-btn color="primary darken-1" text @click="confirmInport">Confirmar</v-btn>
            </div>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-data-footer
        :pagination="pagination"
        :options="options"
        @update:options="updateOptions"
        items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>

    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="getAgents"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import { importAgents } from '../services/agents'

export default {
  name: 'Agents',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogImport: false,
    importCSVFile: null,
    headers: [
      {
        text: 'Agente',
        align: 'start',
        value: 'completeName'
      },
      {
        text: 'DNI/CUIT',
        value: 'dni'
      },
      {
        text: 'Tipo',
        value: 'userType.type'
      },
      {
        text: 'Email',
        value: 'email'
      },
      {
        text: 'Telefono',
        value: 'phone1'
      },
      {
        text: 'Acciones',
        value: 'actions',
        sortable: false
      }
    ],
    agents: [],
    agentsFiltered: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      dni: '',
      tipo: '',
      email: '',
      phone1: ''
    },
    defaultItem: {
      name: '',
      dni: '',
      tipo: '',
      email: '',
      phone1: ''
    },
    search: ''
  }),

  computed: {
    ...mapGetters(['userToken']),
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    search () {
      this.searchAgents()
    }
  },

  async created () {
    this.handleLoading(true)
    await this.getAgents()
    this.handleLoading(false)
  },

  methods: {
    ...mapActions(['storeAgents', 'handleLoading']),
    goToAgentPage () {
      // :to="{path: '/cliente'}"
      this.$router.push('/agente')
    },
    async getAgents () {
      try {
        const res = await axios.get('https://gwinn.com.ar/API/v1/agents.php', {
          headers: {
            Authorization: 'Bearer ' + this.userToken
          }
        })
        if (res.data.status === 200) {
          this.agents = res.data.data
          this.agents.forEach(c => {
            if (c.userType.id === 1) {
              c.completeName = c.lastname + ' ' + c.name
            } else {
              c.completeName = c.businessData.name
            }
          })
          this.storeAgents(this.agents)
          this.searchAgents()
        } else {
          this.responseLogin = res.data.response
        }
        return res
      } catch (err) {
        this.responseLogin = 'Surgio un error al intentar ingresar. Por favor intente mas tarde.'
        throw err.response
      }
    },

    searchAgents () {
      this.agentsFiltered = [...this.agents]
      if (this.search !== '') {
        this.agentsFiltered = this.agentsFiltered.filter(c =>
          c.name.toLowerCase().includes(this.search.toLowerCase()) ||
          c.lastname.toLowerCase().includes(this.search.toLowerCase()) ||
          c.businessData?.name.toLowerCase().includes(this.search.toLowerCase()) ||
          c.dni.includes(this.search)
        )
      }
    },

    editItem (item) {
      this.$router.push({ path: '/agente/' + item.id })
    },

    deleteItem (item) {
      this.editedIndex = this.agents.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      this.closeDelete()
      const agentToDelete = this.editedItem
      this.handleLoading(true)
      try {
        const res = await axios.delete('https://gwinn.com.ar/API/v1/agents.php', {
          headers: {
            Authorization: 'Bearer ' + this.userToken
          },
          data: {
            id: this.editedItem.id
          }
        })
        if (res.data.status === 200) {
          const index = this.agents.findIndex(u => u.id === agentToDelete.id)
          if (index !== -1) {
            this.agents.splice(index, 1)
            this.searchAgents()
          }
          // this.agentsFiltered.splice(this.editedIndex, 1)
          // this.searchAgents()
        } else {
          if (res.data.status === 400) {
            this.$router.push('/login')
          } else {
            alert(res.data.response)
          }
        }
        return res
      } catch (err) {
        this.responseLogin = 'Surgio un error al intentar ingresar. Por favor intente mas tarde.'
        throw err.response
      } finally {
        this.handleLoading(false)
      }
    },

    async confirmInport () {
      if (this.importCSVFile.type !== 'text/csv') {
        alert('El archivo debe ser CSV')
      } else {
        const formData = new FormData()
        formData.append('csv-agents', this.importCSVFile)
        this.closeImport()
        this.handleLoading(true)
        try {
          const res = await importAgents(this.userToken, formData)
          console.log(res)
          await this.getAgents()
        } catch (error) {
          console.log(error)
        } finally {
          this.handleLoading(false)
        }
      }
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeImport () {
      this.dialogImport = false
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.agents[this.editedIndex], this.editedItem)
      } else {
        this.agents.push(this.editedItem)
      }
      this.close()
    },

    openImportDialog () {
      this.dialogImport = true
    },

    exportAgents () {
      // console.log('exportar agentes')
      window.open('https://gwinn.com.ar/API/v1/export-agents.php', '_blank')
    }
  }
}
</script>
