import axios from 'axios'
const url = 'https://gwinn.com.ar/API/v1/'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getAllAgents = async (token, user) => {
  try {
    const res = await axios.get(url + 'agents.php', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const createAgent = async (token, user) => {
  try {
    const res = await axios.post(url + 'agents.php', user, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateAgent = async (token, user) => {
  try {
    const res = await axios.post(url + 'agents.php', user, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const importAgents = async (token, formData) => {
  try {
    const res = await axios.post(url + 'import-agents.php', formData, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllAgentTypes = async (token) => {
  try {
    const res = await axios.get(url + 'agentTypes.php', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
//
// export const deleteUser = async (token, id) => {
//   try {
//     const res = await axios.delete(`/users/${id}`, configToken(token))
//     return res
//   } catch (err) {
//     throw err.response
//   }
// }
